import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Popup from './components/Popup';

function App() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const words = [
    { text: 'Massage', color: 'red' },
    { text: 'Bike Hire', color: 'orange' },
    { text: 'Hair Cut', color: 'yellow' },
    { text: 'Tours', color: 'green' },
    { text: 'Photography', color: 'cyan' },
    { text: 'Make Up', color: 'blue' },
    { text: 'Childcare', color: 'purple' },
    { text: 'Home Repair', color: 'pink' },
    { text: 'Personal', color: 'green' }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupVisible(true);
    }, 3000);

    const wordRotation = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearInterval(wordRotation);
    };
  }, []);

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const openPopup = () => {
    setIsPopupVisible(true);
  };

  return (
    <div className="container">
      <Navbar openPopup={openPopup} />
      <div className="content">
        <span className="headline">We come Tuyu!</span>
        <div className='desc-container'>
          <span className="description">Bali's most reliable service on-demand app. Say goodbye to mindless scrolling and hello to instant access with just a tap.</span>
          <div className="rotating-text">
            <span>What ever you need, </span>
            <span className="rotating-word" style={{ color: words[currentWordIndex].color }}>
              {words[currentWordIndex].text}
            </span>
          </div>
        </div>
      </div>
      {isPopupVisible && <Popup closePopup={closePopup} />}
    </div>
  );
}

export default App;