import React, { useState } from 'react';
import './Popup.css';

const Popup = ({ closePopup }) => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        const name = e.target.elements.name.value;
        const email = e.target.elements.email.value;

        window.location.href = `mailto:info@tuyubali.com?subject=Signup&body=Full Name: ${name}%0AEmail: ${email}`;

        setTimeout(() => {
            closePopup();
        }, 2000);
    };

    return (
        <div className="popup" onClick={closePopup}>
            <div className="popup-content" onClick={e => e.stopPropagation()}>
                <span className="close-btn" onClick={closePopup}>&times;</span>
                {!formSubmitted ? (
                    <>
                        <h2>SIGN UP TODAY</h2>
                        <hr />
                        <p>Have a business that you want to get listed? Or perhaps you would just like to know more about what we do? Enter your details below and we will be in contact!</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <input type="text" name="name" placeholder="Full Name" required />
                                <input type="email" name="email" placeholder="Email" required />
                            </div>
                            <button type="submit">Sign Up</button>
                        </form>
                    </>
                ) : (
                    <div className="thank-you-message">
                        <h2>Thank you!</h2>
                        <p>We will be in touch.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Popup;
