import React from 'react';
import './Navbar.css';
import siteLogo from '../assets/images/logo.png';
import tiktokLogo from '../assets/images/tiktok.png';
import instagramLogo from '../assets/images/instagram.png';

const Navbar = ({ openPopup }) => (
    <div className="navbar">
        <img src={siteLogo} alt="Site Logo" className="site-logo" />
        <div className="nav-right">
            <button className="show-popup-btn" onClick={openPopup}>Sign Up</button>
            <a href="https://www.tiktok.com/@tuyu.bali" target="_blank" rel="noopener noreferrer">
                <img src={tiktokLogo} alt="TikTok" className="social-logo" />
            </a>
            <a href="https://www.instagram.com/tuyu.bali" target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" className="social-logo" />
            </a>
        </div>
    </div>
);

export default Navbar;
